document.addEventListener("turbolinks:load", function () {
  (function () {
    "use strict";
    window.addEventListener(
      "load",
      function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
              }
              form.classList.add("was-validated");
            },
            false
          );
        });
      },
      false
    );
  })();
  let currencyCode;
  $(".bnb_offer").val(null);
  $(".client_offer").val(null);

  $(document).on("change", ".sale-prices .currency-select", function () {
    currencyCode = $(this).val();
    var selected = $(this)
      .parent()
      .parent()
      .parent()
      .parent()
      .find(".dress-choice");
    getDressPrices(selected, currencyCode);
  });
  // if page is appointment form
  if (document.querySelectorAll("#appointment_parent_service").length) {
    var services;
    $.ajax({
      url: "/actions/get_service_list",
      dataType: "json",
    }).done((data) => {
      services = data;
    });

    $("#appointment_parent_service").on("change", function () {
      let curr_val = parseInt($(this).val());
      let new_ser = services.filter(
        (service) => service.parent_id === curr_val
      );
      $("#appointment_appointment_type_id").empty();
      new_ser.forEach((element) => {
        $("#appointment_appointment_type_id").append(
          `<option value="${element.id}">${element.title}</option>`
        );
      });
    });
  }

  // $(document).on("blur", 'input[name="appointment[schedule_date]"]', function () {
  // // $(document).on("blur", 'input#appointment_schedule_date', function () {
  //   let date = $("#appointment_schedule_date").val();
  //   getTimeSlot(date)
  // });

  // if(document.querySelectorAll("#appointment_schedule_date").length > 0){
  //   let date = $("#appointment_schedule_date").val();
  //   getTimeSlot(date)
  // }

  $("#dress-choice-accessories .form-row").on(
    "change",
    ".accessory-select",
    function () {
      // console.log($(this))
    }
  );

  if (document.querySelectorAll("#dress-choice-accessories").length) {
    let accessories;
    let prices;
    $.ajax({
      url: "/actions/get_accessory_prices",
      dataType: "json",
    }).done((data) => {
      // console.log(data)
      prices = data.prices;
      accessories = data.accessories;
      console.log(prices);
    });

    $(document).on("change", "select#accessory_cat", function () {
      let currentCat = $(this).val();
      let accessorySelect = $(this).parent().parent().find(".accessory-select");
      let priceSelect = $(this)
        .parent()
        .parent()
        .find(".accessory-select-price");
      priceSelect.empty();
      accessorySelect.empty();
      accessorySelect.append(`<option value="" label=" "></option>`);
      accessories
        .filter((accessory) => accessory.category === currentCat)
        .forEach((element) => {
          accessorySelect.append(
            `<option value="${element.id}">${element.title}</option>`
          );
        });
    });

    // let currentCat = $("select#accessory_cat").val()
    // let accessorySelect = $(this).parent().parent().find(".accessory-select")
    // let priceSelect = $(this).parent().parent().find(".accessory-select-price")
    // priceSelect.empty()
    // accessorySelect.empty()
    // accessorySelect.append(`<option value="" label=" "></option>`)
    //   accessories.filter(accessory => accessory.category === currentCat).forEach(element =>{
    //     accessorySelect.append(`<option value="${element.id}">${ element.title }</option>`)
    //   })

    $(document).on(
      "change",
      "#dress-choice-accessories select.accessory-select",
      function () {
        let accessoryID = parseInt($(this).val());
        let priceSelect = $(this)
          .parent()
          .parent()
          .find(".accessory-select-price");
        priceSelect.empty();
        priceSelect.append(`<option value="" label=" "></option>`);
        prices
          .filter((price) => price.accessory_id === accessoryID)
          .forEach((element) => {
            priceSelect.append(
              `<option value="${element.price}">${element.price_type}-${element.price}</option>`
            );
          });
      }
    );
  }

  $(".dress_tried_count").val(0);
  $(".dress_tried_amount").val(0);
  $(".dress_tried_count").on("change", function () {
    $(".dress_tried_amount").val(
      calculateDressTriedAmount(parseInt($(this).val()))
    );
    $(".dress-tried-hidden").val(
      calculateDressTriedAmount(parseInt($(this).val()))
    );
  });

  $(document).on("change", ".dress-choice", function () {
    currencyCode = $(this)
      .parent()
      .parent()
      .parent()
      .parent()
      .parent()
      .find(".currency-select")
      .val();
    var selected = $(this);
    getDressPrices(selected, currencyCode);
  });

  if (document.querySelectorAll("#accessory_summary").length > 0) {
    let accessories;
    let prices;
    $.ajax({
      url: "/actions/get_accessory_prices",
      dataType: "json",
    }).done((data) => {
      console.log(data);
      prices = data.prices;
      console.log(prices);
      accessories = data.accessories;
      let currentCat = $("#accessory_cat").val();
      let accessorySelect = $("#accessory_cat")
        .parent()
        .parent()
        .find(".accessory-select");
      let priceSelect = $("#accessory_cat")
        .parent()
        .parent()
        .find(".accessory-select-price");
      priceSelect.empty();
      accessorySelect.empty();
      accessorySelect.append(`<option value="" label=" "></option>`);
      console.log(accessories);
      accessories
        .filter((accessory) => accessory.category === currentCat)
        .forEach((element) => {
          accessorySelect.append(
            `<option value="${element.id}">${element.title}</option>`
          );
        });
    });

    $(document).on("change", "select.accessory-select", function () {
      let accessoryID = parseInt($(this).val());
      let priceSelect = $(this)
        .parent()
        .parent()
        .find(".accessory-select-price");
      priceSelect.empty();
      priceSelect.append(`<option value="" label=" "></option>`);
      prices
        .filter((price) => price.accessory_id === accessoryID)
        .forEach((element) => {
          priceSelect.append(
            `<option value="${element.price}">${element.price}</option>`
          );
        });
    });

    $("select.accessory-select").each(function () {
      let accessoryID = parseInt($(this).val());
      let priceSelect = $(this)
        .parent()
        .parent()
        .find(".accessory-select-price");
      priceSelect.empty();
      priceSelect.append(`<option value="" label=" "></option>`);
      prices
        .filter((price) => price.accessory_id === accessoryID)
        .forEach((element) => {
          priceSelect.append(
            `<option value="${element.price}">${element.price}</option>`
          );
        });
    });
  }

  // Get Flower Girl Dress Price in FLower Girl Appointment Summary
  $(document).on("change", ".flower-girl-select", function () {
    // console.log($(this).val())
    let currentSelect = $(this);
    let itemId = $(this).val();
    $.ajax({
      url: `/flower_girl_dresses/${itemId}/get_price`,
      dataType: "json",
      data: { id: $(this).val() },
    }).done(function (data) {
      console.log(data);
      let discountField = currentSelect.parent().parent().find(".fld-discount");
      let priceField = currentSelect.parent().parent().find(".fld-price");
      // console.log(discountField)
      priceField.val(data.price);
    });
  });

  $(document).on("blur", ".fld-discount", function () {
    // let discountVal = $(this).val()
    // // if (parseFloat(discountVal) !== "" ){
    // //   console.log( $(this).val() )
    // // }
    // let priceField = $(this).parent().parent().find('.fld-price')
    // let oldPriceVal = priceField.val()
    // console.log(oldPriceVal)
    // let newPriceVal = oldPriceVal - ( (parseFloat(discountVal) /100 ) * parseFloat(oldPriceVal) )
    // console.log(newPriceVal)
    // priceField.val(newPriceVal)
  });
});

// function to calculate amount for dress tried
// each dress is 1000, if 3 dress, 2500, after 3 dress add 100

const calculateDressTriedAmount = (dressCount) => {
  let returnAmount = 0;
  if (dressCount >= 1) {
    returnAmount = dressCount * 1000;
  }
  // if (dressCount >=1 && dressCount <3) { returnAmount = dressCount * 1000 }
  // else if (dressCount == 3){ returnAmount = 2500}
  // else if (dressCount > 3){ returnAmount = 2500 + ((dressCount - 3)*1000)}
  else if (dressCount == 0) {
    returnAmount = 0;
  } else if (dressCount == "") {
    returnAmount = 0;
  }
  return returnAmount;
};

function dressChoiceDetail(price1, price2, price3) {
  // function dressChoiceDetail(){

  var html = `
    <div class="row">
    <div class="col-4">
      <dl>
        <dt>Original Price</dt>
        <dd>${price1}</dd>
      </dl>
    </div>
    <div class="col-4">
      <dl>
        <dt>Discount Price</dt>
        <dd>${price2}</dd>
        </dl>
    </div>
    <div class="col-4">
      <dl>
        <dt>Absolute Last Price</dt>
        <dd>${price3}</dd>
      </dl>
    </div>
  </div>`;
  return html;
}

function getTimeSlot(date) {
  if (Date.parse(date)) {
    $.ajax({
      url: "/actions/get_ava_time_slot",
      dataType: "json",
      data: { date: date },
    }).done(function (data) {
      // console.log(data)
      $("#appointment_time_slot").empty();

      data.forEach((element) => {
        $("#appointment_time_slot").append(
          `<option value="${element}">${element}</option>`
        );
      });
    });
  }
}

function getDressPrices(selected, currencyCode) {
  if ($(selected).val() !== "") {
    $.ajax({
      url: "/actions/get_dress_detail",
      dataType: "json",
      data: { id: selected.val() },
    }).done(function (data) {
      let originalPrice = data.dress_prices
        .filter((element) => element.currency_id == currencyCode)
        .find((element) => element.price_type == "Original Price").amount;
      let discountPrice = data.dress_prices
        .filter((element) => element.currency_id == currencyCode)
        .find((element) => element.price_type == "Discount Price").amount;
      let absolutePrice = data.dress_prices
        .filter((element) => element.currency_id == currencyCode)
        .find((element) => element.price_type == "Absolute Price").amount;
      $(selected)
        .parent()
        .parent()
        .parent()
        .parent()
        .find("div#dress-choice-detail")
        .empty();
      // console.log( $(selected).parent().parent().parent().parent().find(".bnb_offer") )
      $(selected)
        .parent()
        .parent()
        .parent()
        .parent()
        .find(".bnb_offer")
        .empty();
      $(selected)
        .parent()
        .parent()
        .parent()
        .parent()
        .find(".bnb_offer")
        .append(
          `<option value="${originalPrice}">Original Price-${originalPrice}</option>`
        );
      $(selected)
        .parent()
        .parent()
        .parent()
        .parent()
        .find(".bnb_offer")
        .append(
          `<option value="${discountPrice}">Discount Price-${discountPrice}</option>`
        );
      $(selected)
        .parent()
        .parent()
        .parent()
        .parent()
        .find(".bnb_offer")
        .append(
          `<option value="${absolutePrice}">Absolute Price-${absolutePrice}</option>`
        );

      $(selected)
        .parent()
        .parent()
        .parent()
        .parent()
        .find("div#dress-choice-detail")
        .append(dressChoiceDetail(originalPrice, discountPrice, absolutePrice));
      $(selected)
        .parent()
        .parent()
        .parent()
        .parent()
        .find("div#dress-choice-detail")
        .show();
    });
  } else {
    $(this)
      .parent()
      .parent()
      .parent()
      .parent()
      .find("div#dress-choice-detail")
      .hide();
    $(selected).parent().parent().parent().find(".bnb_offer").empty();
  }
}
