document.addEventListener("turbolinks:load", function () {
  let currencyCode;
  currencyCode = $(".currency-select").val();

  if ($(".currency-select").length > 0) {
    $.ajax({
      url: "/get_currency_vat",
      data: { id: currencyCode },
    }).then(function (data) {
      // console.log(data.value)
      $(".vat-percentage").val(data.value);
    });
  }

  $("#order_client_id").select2({
    placeholder: "Search Client",
    theme: "bootstrap4",
    width: "100%",
  });

  $(document).on("change", "#order_currency_id.currency-select", function () {
    // console.log("just chnages")
    currencyCode = $(this).val();
    $.ajax({
      url: "/get_currency_vat",
      data: { id: currencyCode },
    }).then(function (data) {
      // console.log(data.value)
      $(".vat-percentage").val(data.value);
    });
  });

  $(".apply-vat-control").each(function () {
    let checked = this.checked;
    // console.log(checked)
    let parent = $(this).closest(".form-group");
    if (checked) {
      $(parent).find(".vat-percentage").show();
    }
  });

  $(document).on("change", ".apply-vat-control", function () {
    let checked = this.checked;
    let parent = $(this).closest(".form-group");
    if (checked) {
      $(parent).find(".vat-percentage").show();
      $(parent).find(".vat-percentage").prop("required", true);
    } else {
      $(parent).find(".vat-percentage").hide();
      $(parent).find(".vat-percentage").prop("required", false);
      $(parent).find(".vat-percentage").val(0.0);
    }
  });

  $(".apply-discount-control").each(function () {
    let checked = this.checked;
    let parent = $(this).closest(".form-group");
    if (checked) {
      $(parent).find(".order-discount").show();
    }
  });

  $(document).on("change", ".apply-discount-control", function () {
    let checked = this.checked;
    let parent = $(this).closest(".form-group");
    if (checked) {
      $(parent).find(".order-discount").show();
      $(parent).find(".order-discount").prop("required", true);
    } else {
      $(parent).find(".order-discount").hide();
      $(parent).find(".order-discount").prop("required", false);
    }
  });

  $(".rental-control").on("change", function () {
    let checked = this.checked;
    let parent = $(this).closest(".prices");
    if (checked) {
      $(parent).find(".rental-price").show();
      // $(parent).find(".sale-prices").hide()
    } else {
      $(parent).find(".rental-price").hide();
      // $(parent).find(".sale-prices").show()
    }
  });

  $(".discounted").each(function () {
    let checked = this.checked;
    let parent = $(this).closest(".order-item");
    if (checked) {
      $(parent).find(".discount_amount").show();
    }
  });

  $(document).on("change", ".discounted", function () {
    let checked = this.checked;
    console.log(checked);
    let parent = $(this).closest(".order-item");
    console.log($(this));
    if (checked) {
      // $(".discount_amount").show()
      $(parent).find(".discount_amount").show();
    } else {
      // $(".discount_amount").hide()
      $(parent).find(".discount_amount").hide();
    }
  });

  $(".is_rental").each(function () {
    let checked = this.checked;
    let parent = $(this).closest(".order-item");
    if (checked) {
      $(parent).find(".security_deposit").show();
      $(parent).find(".label_net_amount")[0].innerHTML = "Sale Amount";
    }
  });

  $(document).on("change", ".is_rental", function () {
    let checked = this.checked;
    // console.log(checked)
    let parent = $(this).closest(".order-item");
    // console.log(parent)
    if (checked) {
      $(parent).find(".security_deposit").show();
      $(parent).find(".label_net_amount")[0].innerHTML = "Rent Amount";
    } else {
      $(parent).find(".security_deposit").hide();
      $(parent).find(".label_net_amount")[0].innerHTML = "Sale Amount";
    }
  });

  // $(document).on("change", ".orderable_type", function(){
  //   let thisParent = $(this).parent().parent()
  //   let orderable_type = $(this).val()
  //   if(orderable_type == "Other"){
  //     console.log("other")
  //     $(thisParent).find(".orderable_id").hide()
  //   }else{
  //     $.ajax({
  //       url: "/order_type_select",
  //       data: {id: orderable_type}
  //     }).done(function(data){
  //       // console.log(data)
  //       $(thisParent).find(".orderable_id").empty()
  //       let emptySelect = `<option value=""></option>`
  //       if (orderable_type == "Dress" || orderable_type == "Accessory"){
  //         $(thisParent).find(".orderable_id").append(emptySelect)
  //         data.forEach(element => {
  //           $(thisParent).find(".orderable_id").append(`<option value="${element.id}">${element.tag_number}</option>`);
  //           // $(thisParent).find(".orderable_id").append(`<option value="${element.id}">${element.tag_number}-${element.title}</option>`);
  //         })
  //       }else if (orderable_type == "CustomDress" ){
  //         $(thisParent).find(".orderable_id").append(emptySelect)
  //         data.forEach(element => {
  //           $(thisParent).find(".orderable_id").append(`<option value="${element.id}">${ element.title }</option>`);
  //         })
  //       }
  //       else if (orderable_type == "Laundry" ){
  //         $(thisParent).find(".orderable_id").append(emptySelect)
  //         data.forEach(element => {
  //           $(thisParent).find(".orderable_id").append(`<option value="${element.id}">${ element.name }</option>`);
  //         })
  //       }
  //       else if (orderable_type == "Adjustment" ){
  //         $(thisParent).find(".orderable_id").append(emptySelect)
  //         data.forEach(element => {
  //           $(thisParent).find(".orderable_id").append(`<option value="${element.id}">${ element.name }</option>`);
  //         })
  //       }
  //       else if (orderable_type == "FlowerGirlDress" ){
  //         $(thisParent).find(".orderable_id").append(emptySelect)
  //         data.forEach(element => {
  //           $(thisParent).find(".orderable_id").append(`<option value="${element.id}">${ element.title }</option>`);
  //         })
  //       }
  //     })

  //   }
  // })

  // $(document).on("change", ".orderable_id", function(){
  //   let thisParent = $(this).closest(".order-item")
  //   let itemID = $(this).val()
  //   let currentType = $(thisParent).find(".orderable_type").val()
  //   $(thisParent).find(".item_net_amount").val("")
  //   if (currencyCode == ""){
  //     alert("Select Order Currency")
  //     return null;
  //   }
  //   $.ajax({
  //     url: "/order_item_price",
  //     data: {type: currentType, id: itemID, currencyCode: currencyCode}
  //   }).done(function(data){
  //     // console.log(data)
  //     console.log(currentType)
  //     if (currentType === "Dress"){
  //       // console.log(data.dress_prices.filter(element => element.currency_id == currencyCode).find(element => element.price_type == "Original Price"))
  //       let dressPrice = data.dress_prices.filter(element => element.currency_id == currencyCode).find(element => element.price_type == "Original Price").amount
  //       $(thisParent).find(".item_net_amount").val(dressPrice)
  //     }
  //     if (currentType === "CustomDress"){
  //       // let dressPrice = data.dress_prices.filter(element => element.currency_id == currencyCode).find(element => element.price_type == "Original Price").amount
  //       $(thisParent).find(".item_net_amount").val(data.price)
  //     }
  //     if (currentType === "Accessory"){
  //       console.log("i am here")
  //       $(thisParent).find("datalist#net_amount").empty()
  //       $(thisParent).find(".item_net_amount").val("")
  //       data.prices.forEach(element => {
  //         $(thisParent).find("datalist#net_amount").append( `<option value="${element.price}"></option>`)
  //       })
  //       // $("datalist#net_amount").append(`<option value="1"></option>`)

  //     }

  //   })
  // })

  if (document.querySelectorAll(".order-items").length) {
    let items;
    $.ajax({
      url: "/get_order_items",
      dataType: "json",
    }).done(function (data) {
      console.log(data);
      items = data;
    });

    $(document).on("change", ".order-type", function () {
      let currentOrderType = $(this).val();
      console.log(currentOrderType);
    });

    $(document).on("change", ".order_item_type", function () {
      let currentSelect = $(this);
      let itemType = $(this).val();
      let desc_select = currentSelect.parent().parent().find(".order_item_id").empty();
      desc_select.append(`<option value=""></option>`);
      // console.log(items)
      if (itemType == 1) {
        items.dresses.forEach((element) => {
          desc_select.append(`<option value="${element.id}">${element.title}</option>`);
        });
      } else if (itemType == 2) {
        items.adjustment.forEach((element) => {
          desc_select.append(`<option value="${element.id}">${element.name}</option>`);
        });
      } else if (itemType == 3) {
        items.laundry.forEach((element) => {
          desc_select.append(`<option value="${element.id}">${element.name}</option>`);
        });
      } else if (itemType == 4) {
        items.accessory.forEach((element) => {
          desc_select.append(`<option value="${element.id}">${element.title}</option>`);
        });
      }
    });

    $(document).on("change", ".order_item_id", function () {
      let currVal = $(this).val();
      let itemType = $(this).parent().parent().find(".order_item_type").val();
      console.log(itemType);
      let orderableType = $(this).parent().parent().find(".orderable_type");
      let orderItemDesc = $(this).parent().parent().find(".order_item_desc");
      // console.log(itemType)
      if (itemType == 1) {
        let selectedItem = items.dresses;
        console.log(currVal);
        let currItem = selectedItem.find((element) => element.id == currVal);
        console.log(currItem.title);
        orderableType.val("Dress");
        orderItemDesc.val(currItem.title);
        $(this).parent().parent().find(".order_item_amount").val(currItem.original_price);
      } else if (itemType == 4) {
        let selectedItem = items.accessory;
        console.log(currVal);
        let currItem = selectedItem.find((element) => element.id == currVal);
        console.log(currItem.title);
        orderableType.val("Accessory");
        orderItemDesc.val(currItem.title);
      } else if (itemType == 2) {
        let selectedItem = items.adjustment;
        let currItem = selectedItem.find((element) => element.id == currVal);
        orderableType.val("Addon");
        orderItemDesc.val(currItem.name);
      } else if (itemType == 3) {
        let selectedItem = items.laundry;
        let currItem = selectedItem.find((element) => element.id == currVal);
        orderableType.val("Addon");
        orderItemDesc.val(currItem.name);
      }
    });
  }
});
