require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import "trix"
import "@rails/actiontext"
// require("simple_calendar")
import 'bootstrap';
import 'select2'
import 'overlayscrollbars'
// import 'admin-lte'
// import 'bootstrap-datepicker'
import { jsPDF } from "jspdf";

import $, { ajax } from 'jquery';
global.$ = jQuery;
import toastr from 'toastr';
window.toastr = toastr;
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css"
import "./custom-confirm"
// import datepicker from 'bootstrap-datepicker'
// window.$ = window.jquery = jquery;
// window.datepicker = datepicker

import "datatables.net-bs4"
import "datatables.net-searchpanes-bs4"
import 'daterangepicker';
import moment from 'moment';
window.moment = moment


import "./appointments"
import "./order_form"
import "./tables"

import "../stylesheets/admin";
import "../js/jquery.masknumber.min"

import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
window.intlTelInput = intlTelInput;
// window.daterangepicker = daterangepicker

function myDateFormat(myDate,period){
  let year = myDate.getFullYear()
  const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
  }
  let month = months[myDate.getMonth()]
  let date = myDate.getDate()
  return `${month} ${date} ${year}`
}

async function myFetch() {
  let response = await $.get('/current_user', (result) => result.name);
  // if (!response.ok) {
  //   throw new Error(`HTTP error! status: ${response.status}`);
  // }
  return await response.name
}

document.addEventListener("turbolinks:load", async function () {
  require('../js/admin-lte');
  // $('#client-list').DataTable();
  // $('#dress-list').DataTable({
  //   "columnDefs": [
  //     { "searchable": false, "targets": 0 }
  //   ]
  // });
  $('#flower-dress-list').DataTable();
  $('#accessory-list').DataTable();
  $('#custom-dress-list').DataTable();
  $('#emp-request').DataTable();
  // var appTable = $('#appointment-list').DataTable({"ordering": false});
  // $("#appointment_status").on( 'change', function () {
  //   console.log(this.value)
  //   var selected = this.value
  //       appTable
  //           .column(5)
  //           .data()
  //           .search(selected)
  //           .draw();
  //   } );
  // appTable.column(2).search(myDateFormat(todayDate)).draw();
  
  $('.carousel').carousel('cycle')

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  var navListitem = $('li.nav-item')
  navListitem.each(function(){
    var activeChild = $(this).find(".active")
    if (activeChild.length>0){
      $(this).addClass("menu-open")
    }
  })

  // Set Order Currency
  let currencyCode
  currencyCode = $(".currency-select").val()
  // $(document).on('change', ".currency-select", function(){
  //   currencyCode = $(this).val()
  //   $.ajax({
  //     url: '/admin/get_currency_vat',
  //     data: {id: currencyCode}
  //   }).then(function(data){
  //     console.log(data.value)
  //     $(".vat-percentage").val(data.value)
  //   })
    
  // })

  $(document).on('change', '.item-type', function(){
    let parent = $(this).closest(".form-row")
     if ($(this).val() === "image"){
        $(parent).find(".image-field").show()
        $(parent).find(".bnb-dress").hide()
     } else{
      $(parent).find(".image-field").hide()
      $(parent).find(".bnb-dress").show()
     }
  })

  $("select.item-type").each(function(){
    let parent = $(this).closest(".form-row")
     if ($(this).val() === "image"){
        $(parent).find(".image-field").show()
        $(parent).find(".bnb-dress").hide()
     } else{
      $(parent).find(".image-field").hide()
      $(parent).find(".bnb-dress").show()
     }
  })

  if ( document.querySelectorAll(".show-dress-due-date").length > 0){
    
    $(".display-custom-dress-due-date").show()
    $('.custom-dress-due-dat').html($(".show-dress-due-date").val())

  }
 
  $(document).on('change', '.custom_item_type', function(){
      if( $(this).val() === "bnb"){
        $(this).parent().parent().find(".bnb_custom_dress_select").removeClass("hide-form-field")
        $(this).parent().parent().find(".custom_other_image").addClass("hide-form-field")
      }else if ($(this).val() === "other"){
        $(this).parent().parent().find(".bnb_custom_dress_select").addClass("hide-form-field")
        $(this).parent().parent().find(".custom_other_image").removeClass("hide-form-field")
      }else{
        $(this).parent().parent().find(".bnb_custom_dress_select").addClass("hide-form-field")
        $(this).parent().parent().find(".custom_other_image").addClass("hide-form-field")
      }
  })

  // $(document).on('change', '.referral_type', function(){
  //   if( $(this).val() == "Event Planner" || $(this).val() == "BnB Bride" || $(this).val() == "Other" ){
  //     $('.referral_value').removeClass("hidden")
  //     if($(this).val() == "BnB Bride"){
  //     }
  //   }
  //   else{
  //     $('.referral_value').addClass("hidden")
  //   }
  // })
 
  $(document).on('click', '#add-follow-up-message', function(){
    if($(this).hasClass('disabled')){ return null }
    let followupID = ( $(this).data('followup-id') )
    $.ajax({
      url: '/follow_up_message',
      data: {id: followupID}
    })
  })

  $(document).on('click', "#create-payment", function(){
    // $('#paymentModal').modal()
    let invoiceID = ( $(this).data('invoice-id') )
    $.ajax({
      url: '/trial_invoice_payment',
      data: {id: invoiceID}
    })
  })

  $(document).on('click', "#order-payment", function(){
    // $('#paymentModal').modal()
    let orderID = ( $(this).data('order-id') )
    $.ajax({
      url: '/order_invoice_payment',
      data: {id: orderID}
    })
  })

  $(document).on('click', "#deposit-payment", function(){
    // $('#paymentModal').modal()
    let orderID = ( $(this).data('order-id') )
    $.ajax({
      url: '/security_deposit_payment',
      data: {id: orderID}
    })
  })
 
  $('.dress-choice').select2({
    placeholder: 'Search Dresses',
    theme: 'bootstrap4'
  });

  $('.flower-girl-select').select2({
    placeholder: 'Search Dresses',
    theme: 'bootstrap4'
  });

  $("#custom_dress_client_id").select2({
    placeholder: 'Search Client',
    theme: 'bootstrap4',
    width: '100%'
  });

  
  $('.send-reminder').on('click', function(){
    var appointmentID = $(this).attr("data-attr")
    // console.log(appointmentID)
    $.ajax({
      url: '/actions/send_reminder',
      dataType: 'json',
      data: {appointment_id: appointmentID}
    }).done(function(data){
      toastr.success(data.message)
    })
  })

  // Add dynamic form field
  // $("[data-form-prepend]").on('click', function(e) {
  $(document).on('click', "[data-form-prepend]", function(e) {
    // console.log(e.target)
    var obj = $($(this).attr("data-form-prepend"));
    var randomNum = Math.floor(100000000 + Math.random() * 900000000);
    obj.find("input, select, textarea").each(function() {
      $(this).attr("name", function() {
        return $(this)
          .attr("name")
          .replace("new_record", randomNum);
          // .replace("new_record", new Date().getTime());
      });
      if ( ! ($(this).attr("id") === undefined) ){
        $(this).attr("id", function() {
          return $(this)
            .attr("id")
            .replace("new_record", randomNum);
        });
      }
    });
    obj.find("label").each(function(){
      $(this).attr("for", function(){
        return $(this)
          .attr("for")
          .replace("new_record", randomNum);
      })
    })
    obj.insertBefore(this);
    // console.log($(obj).find(".orderable_id"))

    $(obj).find(".orderable_id").select2({
      // placeholder: 'Search Client',
      theme: 'bootstrap4',
      width: '100%'
    });

    $(obj).find(".flower-girl-select").select2({
      // placeholder: 'Search Client',
      theme: 'bootstrap4',
      width: '100%'
    });

    // $(obj).find('.dress-choice').select2({
    //   placeholder: 'Search Dresses',
    //   theme: 'bootstrap4'
    // });
    // console.log($(this).hasClass(".orderable_id"))
    return false;
  });

  $(document).on('click', '.remove-field', function(){
    $(this).parent().parent().remove()
  })
  $(document).on('click', '.remove-choice', function(){
    $(this).parent().parent().parent().remove()
    if( $('.choices').length == 1 ){
      $('.remove-choice').hide()
    }
  })

  $(document).on('change', '.complete-order', function(){
    console.log(this)
  })

  $(document).on('click', '.send-email', function(){
    $(this).addClass("disabled")
    var modal = $("#emailModal");
    modal.modal();
})

  // end add dynamic form fields

  // $(document).on("change", "#pay-naira", function(){
  //   let checked = this.checked
  //   console.log(checked)
  //   let payment_select = $(this).parent().parent().find('.payment_select')
  //   $(payment_select).empty()
  //   // let parent = $(this).closest(".order-item")
  //   // console.log($(this))
  //   // if (checked){
  //   //   // $(".discount_amount").show()
  //   //   $(parent).find(".discount_amount").show()
  //   // }else{
  //   //   // $(".discount_amount").hide()
  //   //   $(parent).find(".discount_amount").hide()
  //   // }
  // })

});


